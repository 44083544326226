const StatusMixin = {
  data() {
    return {
      statusOptions: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
      ],
    }
  },
}

export default StatusMixin
